/* Geral */
.dashboard-main-content {
  margin: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  align-items: flex-start;
  overflow-y: auto;
  min-height: 100vh; 
  max-height: 100vh;
}

.dashboard-main-content .card {
  background-color: #fff;
  border-radius: 0.625rem;
  border-color: #DCDCDC;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

/* Filtros */
.filters-card {
  margin: 0 12px;
  width: 98% !important;
  display: flex;
  align-items: center !important;
}

.filters-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.filter-btn-container {
  margin-top: 15px;
}

.filter-btn{
  background-color: #0A91FF !important;
  font-family: 'Vazirmatn', sans-serif;
  font-weight: 600;
  min-width: 95px !important;
  height: 38px !important;
}

.filter-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 97%;
  gap: 20px;
}

.filter-title {
  font-weight: 700;
}

/* Títulos */
.dashboard-title-container {
  margin-left: 15px;
  margin-top: 20px;
}

.dashboard-title {
  font-size: 1.5rem;
  font-weight: 700;
}

/* Gráficos */
.dashboard-charts {
  display: grid;
  grid-template-areas:
    "mostCommonGraph quantityGraph"
    "timeGraph genderGraph";
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 10px;
  width: 100%;
}

.dashboard-charts > .most-common-graph-card {
  grid-area: mostCommonGraph;
  height: 350px !important;
  width: 100% !important;
}


.dashboard-charts > .most-common-graph-card .card {
  grid-area: mostCommonGraph;
  height: 350px !important;
  width: 100% !important;
}

.dashboard-charts > .quantity-graph-card {
  grid-area: quantityGraph;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.dashboard-charts > .quantity-graph-card > .card{
  height: 350px !important;
  width: 100% !important;
}

.dashboard-charts > .time-graph-card {
  grid-area: timeGraph;
  height: 350px !important;
  width: 100% !important;
}

.dashboard-charts > .gender-graph-card {
  grid-area: genderGraph;
  display: flex;
  flex-direction: column;
  height: 350px !important;
  width: 100% !important;
}

/* Títulos dos Gráficos */
.graph-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.graph-title {
  font-family: 'Vazirmatn', sans-serif;
  color: #080808;
  font-weight: 600;
}

/* Gráficos por Gênero */
.gender-graph-card-content {
  display: flex;
  align-items: center !important;
  justify-content: space-between;
  padding-right: 80px;
  width: 100% !important;
}

.gender-graph-legend {
  display: flex;
  flex-direction: column;
}

.gender-graph-legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.gender-graph-legend-color {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  margin-right: 8px;
}

.gender-graph-legend-text {
  color: #878787;
  font-family: 'Vazirmatn', sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.gender-graph-legend-percentage {
  display: flex;
  align-items: center;
  font-size: 26px;
  font-weight: bold;
  margin-left: 15px;
}

.gender-graph-legend-percentage-subtitle {
  font-size: 18px;
  font-weight: bold;
  margin-left: 15px;
  color: #878787;
}

.pie-chart {
  width: 300px;
   height: 90%;
}

@media (max-width: 1000px) {
  .dashboard-charts {
    grid-template-areas:
      "mostCommonGraph"
      "quantityGraph"
      "timeGraph"
      "genderGraph";
    grid-template-columns: 1fr;
  }
}

@media (max-width: 1200px) {
  .dashboard-title {
    font-size: 1.4rem;
  }
  .graph-title {
    font-size: 1.15rem;
  }
  .gender-graph-legend-text {
    font-size: 15px;
  }
}

@media (max-width: 1000px) {
  .gender-graph-legend-percentage {
    font-size: 24px;
  }

  .filters-card {
    width: 98% !important;
  }
}

@media (max-width: 768px) {
  .dashboard-title {
    font-size: 1.2rem;
  }
  .graph-title {
    font-size: 1rem;
  }
  .gender-graph-legend-text {
    font-size: 14px;
  }
  .gender-graph-legend-percentage {
    font-size: 22px;
  }
  .filters-card {
    width: 95% !important;
  }
  .dashboard-charts >   .time-graph-card {
    height: 520px !important;
    margin: 10px 0;
  }
}

@media (max-width: 480px) {
  .dashboard-title {
    font-size: 16px;
  }
  .graph-title {
    font-size: 0.9rem;
  }
  .gender-graph-legend-text {
    font-size: 13px;
  }
  .gender-graph-legend-percentage {
    font-size: 20px;
  }
  .card-chart-title {
    font-size: 16px;
  }
  .card-chart-value {
    font-size: 26px;
  }
  .card-chart-subtitle {
   font-size: 14px;
  }
  .gender-graph-card-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
  .dashboard-charts > .gender-graph-card {
    height: 500px !important;
  }
  .filters-card {
    width: 90% !important;
  }
  .pie-chart {
    width: 200px;
     height: 60%;
  }

  .dashboard-charts >   .time-graph-card {
    height: 520px !important;
    margin: 10px 0;
  }
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7); 
  z-index: 9999; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-overlay .spinner {
  color: #1976d2; 
}
