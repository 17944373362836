.tab-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .tab-button {
    padding: 8px 16px;
    height: 32px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 33px;
    background-color: #fff;
    color: #000;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 500;
    flex: 1 0 100%; 
  }
  
  .tab-button.active {
    background-color: #0A91FF;
    color: #fff;
  }
  
  @media (min-width: 601px) and (max-width: 1024px) {
    .tab-button {
      flex: 1 0 auto;
    }
  }
  
  @media (min-width: 1025px) {
    .tab-button {
      flex: 0 0 auto; 
    }
  }
  