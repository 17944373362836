.user-icon {
    width: 35px; 
    height: 35px;
    background-color: #003366; 
    border-radius: 50%; 
    display: flex; 
    align-items: center; 
    justify-content: center;
    overflow: hidden; 
  }
  
.user-icon-image {
    max-width: 100%; 
    max-height: 100%; 
    object-fit: cover; 
}
 
.chat-icon-image {
  max-width: 60%; 
  max-height: 60%; 
  object-fit: cover; 
}
