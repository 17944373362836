.card-chart {
    width: 48% !important;
    font-family: 'Vazirmatn', sans-serif !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    gap: 20px;
    height: 100%; 
    text-align: center; 
}

.card-chart-title-contaier {
    align-items: center;
    justify-content: center; 
    color: #FB3B52;
    width: 100%; 
}

.card-chart-title {
    font-size: 20px;
    font-weight: 600;
    color: #080808 !important;
    margin-top: 10px;
}

.card-chart-value {
    font-size: 34px;
    font-weight: 600;
    color: #080808;
    width: 100%; 
}

.card-chart-subtitle {
    font-size: 16px;
    font-weight: 600;
    color: #9D9D9D;
    width: 100%; 
}
