.suggestion-button {
    background-color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    border: 1px solid #C3C3C3;
    color: rgb(124, 121, 121);
    flex-grow: 0;
    flex-shrink: 0;
  }

@media (max-width: 400px) {
  .suggestion-button {
    padding: 5px 10px;
    font-size: 14px;
  }
}