.chat-main-content {
  margin-left: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  overflow: hidden; 
}

.chat-window {
  margin-bottom: 6rem;
  flex: 1;
  padding: 0.625rem;
  overflow-y: auto !important;
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  width: 1400px;
  max-height: 100%;
  min-height: 40%;
  scrollbar-width: none;
  overflow: auto;
}

.chat-window.chat{
  margin-bottom: 2rem;
  width: 1100px !important;
}

.chat-window::-webkit-scrollbar {
  display: none; 
}

.message {
  margin-bottom: 0.625rem;
  border-radius: 0.625rem;
  max-width: 100%;
  word-wrap: break-word; 
}

.empty-chat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 325px;
}

.assistant-name {
  margin: 0.5rem 0;
  max-width: 433px;
  max-height: 100px;
  font-size: 32px;
  line-height: 50px;
  font-weight: 700;
}

.assistant-sub-name {
  margin: 0.5rem 0;
  max-width: 433px;
  max-height: 26px;
  font-size: 16px;
  font-weight: 400;
}

.assistant-logo {
  width: 60px;
  height: 60px;
  max-width: 100%; 
  border: 1px solid #C3C3C3;
  border-radius: 10px;
  padding: 8px;
}

.suggestions {
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;
  justify-content: center;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  min-height: 3rem;
}

@media (max-width: 1200px) {
  .chat-window.chat{
    width: 85% !important;
  }
}

@media (max-width: 1000px) {
  .assistant-logo {
    margin-top: 20px; 
    width: 50px !important;
    height: 50px !important;
  }

  .assistant-name {
    font-size: 1.8rem !important;
    line-height: 1.2;
  }

  .assistant-sub-name {
    font-size: 0.9rem !important;
  }

  .welcome-message {
    font-size: 1.4rem !important;
  }

  .chat-window.chat{
    width: 85% !important;
  }
}

@media (max-width: 800px) {
  .assistant-logo {
    margin-top: 20px; 
    width: 45px !important;
    height: 45px !important;
  }

  .assistant-name {
    font-size: 1.5rem !important;
    line-height: 1.2;
  }

  .assistant-sub-name {
    font-size: 0,75rem !important;
  }

  .welcome-message {
    font-size: 1rem !important;
  }

  .chat-window.chat{
    width: 75% !important;
  }

  .suggestions {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
