.input-box {
  display: flex;
  width: 1100px;
}

.chat-input-box {
  margin-bottom: 80px;
}

.input-container {
  flex: 1;
  display: flex;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #ABABAB;
  padding: 15px;
  background-color: #FFFFFF;
}

.input-container textarea {
  flex: 1;
  padding: 0; 
  font-size: inherit;
  font-family: inherit; 
  border: none; 
  border-radius: 0; 
  outline: none; 
  resize: none;
}

textarea::placeholder {
  font-size: 15px !important;
}

.input-container .send-button {
  border: none;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 5px;
  background-color: #FFFFFF !important;
  color: #6C6C6C !important;
}
