.sidebar {
  height: 100vh;
  width: 60px;
  background-color: #F0EFEF;
  transition: width 0.3s ease, background-color 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-y: auto; 
  overflow-x: hidden;
}

.sidebar.expanded {
  width: 320px;
  background-color: #FFFFFF;
  align-items: start;

}

.menu-icon {
  font-size: 2rem;
  cursor: pointer;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 5px;
  display: flex;
  align-items: left;
  justify-content: left;
  transition: justify-content 0.3s ease;
  color: #6C6C6C;
}

.history-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 10px;
}

.new-chat {
  display: flex;
  justify-content: center;
  align-items: center; 
  flex-shrink: 0;
  padding: 20px; 
}

.new-chat-btn {
  cursor: pointer;
  width: 100%; 
  max-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

a {
  color: #FFFFFF;
  text-decoration: none !important;
}

.addIcon {
  margin: 20px 0;
  cursor: pointer;
  color: #6C6C6C;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar.expanded .addIcon {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
}

.sidebar .addIcon {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease;
}

.menu-label {
  font-size: 16px;
  font-weight: 400;
  color: #1C1B1F !important;
  margin-left: 10px;
  display: inline-block;
  font-family: 'Vazirmatn', sans-serif;
}

.sidebar.expanded .menu-label {
  display: inline-block;
}

.sidebar .menu-label {
  display: none;
}