.data-table {
    max-width: 730px; 
    border-collapse: collapse; 
    background-color: #FFFFFF; 
}

table {
    width: 100%;
    border: 1px solid #BFD1DB !important; 
    border-radius: 8px 8px 0 0;
    overflow: hidden;
}

th {
    padding: 8px;
    text-align: left;
    background-color: #EDF3F6; 
    border-bottom: 1px solid #BFD1DB !important; 
    border-right: 1px solid #BFD1DB !important;
}

td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #BFD1DB !important;
    border-right: 1px solid #BFD1DB !important;
}

tr:hover {
    background-color: #f5f5f5; 
}
