.chat-history-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin-bottom: 8px;
    font-family: 'Inter', sans-serif !important;
    height: 100%;
    overflow-y: hidden;
}

.chat-history-status-success {
    color: #4EC915;
    width: 12px !important;
    height: 12px !important;
}

.chat-history-status-error {
    color: #A0A0A0;
    width: 12px !important;
    height: 12px !important;
}

.chat-history-title {
    color: #1C1B1F;
    font-size: 14px;
    font-weight: 400;
    overflow-wrap: break-word; 
    word-wrap: break-word; 
    line-height: 15px !important;
    word-break: break-word;
}
