.context-box {
  display: flex;
  background-color: #EDF3F6;
  color: #333;
  padding: 10px 15px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  min-width: 120px;
  max-width: 220px;
  height: 35px;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 7px;
  font-family: 'Inter', sans-serif;
}
