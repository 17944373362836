.chat-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100vh;
}

a {
    text-decoration: none;
    cursor: pointer !important;
}

hr {
    width: 85%;
    margin-top: 17px !important;
    margin-bottom: 25px !important;
}

.history-title {
    display: flex;
    align-items: center;
    color: #1C1B1F;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 40px; 
    font-family: 'Inter', sans-serif !important;
}

.date-title {
    color: #1C1B1F;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 10px;
    font-family: 'Inter', sans-serif !important;
}